import axios from "@/api/axios"

const module = 'autocomplete'

const searchAddress = (params) => {
  return axios.get(`/${module}/address`, {params: params})
}

const searchParty = (params) => {
  return axios.get(`/${module}/party`, {params: params})
}

const searchAssortment = (query) => {
    return axios.get('/assortment/search', {params: query})
}

const searchClient = (query) => {
    return axios.get('/clients/get', {params: query})
}

export default {
  searchAddress,
  searchParty,
  searchAssortment,
  searchClient
}

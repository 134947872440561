<template>
  <div class="calendar page" id="calendar">
    <div class="content">
      <div class="page_header">
        <h1>Замеры и монтаж</h1>
        <el-date-picker
            v-model="date"
            type="date"
            placeholder="Выберите дату" size="mini"
            format="dd.MM.yyyy"
            @change="changeDate"
            style="margin-right: 15px;margin-top: 1px;"
        >
        </el-date-picker>
        <el-button size="mini" type="primary" icon="el-icon-plus" @click="create">Новая запись</el-button>
      </div>


      <div id="schedules" v-loading="loading">
        <div class="times">
          <div class="time"><span></span></div>
          <div class="time"><span>08:00</span></div>
          <div class="time"><span>09:00</span></div>
          <div class="time"><span>10:00</span></div>
          <div class="time"><span>11:00</span></div>
          <div class="time"><span>12:00</span></div>
          <div class="time"><span>13:00</span></div>
          <div class="time"><span>14:00</span></div>
          <div class="time"><span>15:00</span></div>
          <div class="time"><span>16:00</span></div>
          <div class="time"><span>17:00</span></div>
          <div class="time"><span>18:00</span></div>
          <div class="time"><span>19:00</span></div>
          <div class="time"><span>20:00</span></div>
          <div class="time"><span>21:00</span></div>
          <div class="time"><span>22:00</span></div>
        </div>
        <div class="brigade" v-for="brigade in calendar">
          <div class="header">
            {{ brigade.title }}
          </div>
          <div class="timeline">
            <div class="time" v-for="i in 15"></div>
          </div>
          <div class="event"
               v-for="event in brigade.events"
               v-if="brigade.events.length"
               :style="calcPosition(event)"
          >
            <div class="block">
              <template v-if="event.type === 'assessment'">Замер</template>
              <template v-if="event.type === 'mounting'">Монтаж</template>
              <div class="fitter" v-if="event.fitter_id">{{ event.fitter.fio_short }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :visible.sync="showCreator"
        width="900px"
        @close="closeCreator"
        :destroy-on-close="true">
      <div class="createAssessment createEvent form">
        <el-row :gutter="0">
          <el-col :span="8">
            <div class="form_element">
              <label>На что записываем?</label>
              <el-radio-group v-model="newEvent.type" size="mini">
                <el-radio-button label="assessment">Замер</el-radio-button>
                <el-radio-button label="mounting">Монтаж</el-radio-button>
                <el-radio-button label="complaint">Рекламация</el-radio-button>
              </el-radio-group>
            </div>
            <div class="form_element">
              <label>Клиент</label>
              <el-autocomplete
                  popper-class="ac_input_client"
                  v-model="newEvent.client_search"
                  :fetch-suggestions="clientSearch"
                  placeholder="Поиск по имени или телефону"
                  size="mini"
                  :debounce="500"
                  :trigger-on-focus="false"
                  @select="handleSelectClient"
              >
                <template slot-scope="{ item }">
                  <div class="client_row">
                    <div class="name">{{ item.name }}</div>
                    <div class="contacts" v-if="item.contacts.length">
                      <span class="contact" v-for="c in item.contacts">{{ c.value }}</span>
                    </div>
                  </div>
                </template>
              </el-autocomplete>
            </div>
            <div class="form_element" v-if="newEvent.client && newEvent.client.requests">
              <label>Обращение</label>
              <el-select size="mini" v-model="newEvent.request_id">
                <el-option v-for="r in newEvent.client.requests" :label="'№'+r.id +' от ' + moment(r.created_at).format('DD.MM.YYYY HH:mm')" :value="r.id">
                  №{{r.id}} от {{moment(r.created_at).format('DD.MM.YYYY HH:mm')}}
                </el-option>
              </el-select>
            </div>
            <div class="form_element" v-if="newEvent.client && newEvent.client.orders">
              <label>Заказ</label>
              <el-select size="mini" v-model="newEvent.order_id">
                <el-option v-for="o in newEvent.client.orders" :label="'№'+o.number +' от ' + moment(o.created_at).format('DD.MM.YYYY HH:mm')" :value="o.id">
                  №{{o.number}} от {{moment(o.created_at).format('DD.MM.YYYY HH:mm')}} на {{o.sum_wd | price}} р
                </el-option>
              </el-select>
            </div>

            <div class="form_element">
              <label>Кто будет выполнять заявку?</label>
              <el-radio-group v-model="newEvent.f_o_b" size="mini">
                <el-radio-button label="b">Бригада</el-radio-button>
                <el-radio-button label="f">Монтажник</el-radio-button>
              </el-radio-group>
            </div>

            <div class="form_element" v-if="newEvent.f_o_b == 'f'">
              <label>Выберите монтажника</label>
              <el-select v-model="newEvent.fitter_id" size="mini" @change="getSchedule">
                <el-option v-for="f in fitters" :label="f.fio_full" :value="f.id"></el-option>
              </el-select>
            </div>

            <div class="form_element" v-if="newEvent.f_o_b == 'b'">
              <label>Выберите бригаду</label>
              <el-select v-model="newEvent.brigade_id" size="mini" @change="getSchedule">
                <el-option v-for="b in brigades" :label="b.title" :value="b.id"></el-option>
              </el-select>
            </div>

            <div class="form_element">
              <label>На какую дату?</label>
              <el-date-picker
                  v-model="newEvent.start_date"
                  type="date"
                  placeholder="Выберите день" size="mini"
                  format="dd.MM.yyyy"
                  @change="getSchedule"
              >
              </el-date-picker>
            </div>
            <div class="form_element">
              <label>Время</label>
              <el-row :gutter="10" class="time_selector">
                <el-col :span="12">
                  <el-time-select
                      v-model="newEvent.start_time"
                      :picker-options="{
                    start: '07:00',
                    step: '01:00',
                    end: '22:00',
                  }"
                      size="mini"
                      placeholder="От">
                  </el-time-select>

                </el-col>
                <el-col :span="12">
                  <el-time-select
                      v-model="newEvent.end_time"
                      :picker-options="{
                    start: '07:00',
                    step: '01:00',
                    end: '23:00',
                    minTime: newEvent.start_time
                  }"
                      size="mini"
                      placeholder="До">
                  </el-time-select>

                </el-col>
              </el-row>
            </div>

            <div class="form_element">
              <label>Комментарий</label>
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  placeholder="Информация для замерщика"
                  v-model="newEvent.manager_comment">
              </el-input>
            </div>
            <div class="form_element" v-if="newEvent.client">
              <label>Адрес</label>
              <el-select v-if="newEvent.client.addresses" v-model="newEvent.address_id">
                <el-option v-for="a in newEvent.client.addresses" :key="a.address_id" :value="a.address_id"
                           :label="a.value"></el-option>
              </el-select>
              <div v-else>
                <el-alert type="warning">У клиента не указан ни один адрес</el-alert>
              </div>
            </div>

          </el-col>
          <el-col :span="16">
            <div class="schedule">
              <div class="schedule-content" v-if="schedule.length">
                <div class="schedule-event" v-for="event in schedule" :key="event.id">
                  <div class="icon">
                    <v-icon name="pencil-ruler" v-if="event.type == 'assessment'"></v-icon>
                    <v-icon name="hammer" v-if="event.type == 'mounting'"></v-icon>
                  </div>
                  <div class="title">
                    <span class="time">
                      <i class="el-icon-time"></i> {{ moment(event.start_date).format('DD.MM.YYYY')
                      }} c {{ moment(event.start_time, 'HH:mm:ss').format('HH:mm')
                      }} до {{ moment(event.end_time, 'HH:mm:ss').format('HH:mm') }}
                    </span>
                    <span v-if="event.type == 'assessment'">Замер </span>
                    <span v-if="event.type == 'mounting'">Монтаж </span>
                  </div>
                  <div class="address">Адрес: {{ event.address.value }}</div>
                  <div class="installer">
                    <span v-if="event.brigade">Бригада: {{ event.brigade.title }}</span>
                    <span v-if="event.fitter">Монтажник: {{ event.fitter.fio_full }}</span>
                  </div>
                  <div class="comment">
                    {{ event.manager_comment }}
                  </div>
                </div>
              </div>
              <div class="schedule-empty" v-else>
                <p v-if="filterSelected">Нет записей в расписании</p>
                <p v-else>Выберите бригаду/монтажника и дату, чтобы увидеть расписание.</p>
              </div>
            </div>
          </el-col>
        </el-row>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCreator">Отменить</el-button>
        <el-button type="primary" @click="createEvent" :disabled="!canCreate">Записать</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as calendarAction} from "@/store/modules/calendar";

import price from "@/filters/price";

import autocomplete from "@/api/autocomplete";

export default {
  name: "Calendar",
  data() {
    return {
      date: new Date(),
      showCreator: true,
      newEvent: {
        type: 'assessment',
        f_o_b: 'b',
        brigade_id: null,
        fitter_id: null,
        order_id: null,
        request_id: null,
        client_id: null,
        client: null,
        client_search: '',
        address_id: null,
        manager_comment: '',
        start_date: new Date(),
        start_time: null,
        end_date: null,
        end_time: null,
      }
    }
  },
  filters: {
    price
  },
  computed: {
    ...mapState({
      brigades: state => state.brigades.list,
      calendar: state => state.calendar.list,
      loading: state => state.calendar.loading,
      schedule: state => state.calendar.filteredList,
      employees: state => state.employees.list,
    }),
    fitters() {
      return _.filter(this.employees, function (e) {
        if (e.user.role === 'fitter') {
          if (e.first_name == null) e.first_name = '';
          if (e.last_name == null) e.last_name = '';
          if (e.middle_name == null) e.middle_name = '';
          e.full_name = e.last_name + ' ' + e.first_name + ' ' + e.middle_name
          return e
        }
      })
    },
    filterSelected() {
      if (this.newEvent.f_o_b == 'f') {
        if (this.newEvent.fitter_id == null || this.newEvent.start_date == null)
          return false
      } else {
        if (this.newEvent.brigade_id == null || this.newEvent.start_date == null)
          return false
      }
      return true
    },
    canCreate() {
      if (this.filterSelected) {
        if (this.newEvent.type === 'assessment') {
          if (this.newEvent.order_id != null || this.newEvent.request_id != null) {
            return true
          }
          return false
        } else {
          if (this.newEvent.order_id != null) {
            return true
          }
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    createEvent() {
      this.$store.dispatch(calendarAction.create, this.newEvent).then(() => {
        this.showCreator = false;
        this.loadData()
      })
    },
    closeCreator() {
      this.showCreator = false
    },
    create() {
      this.showCreator = true
      this.newEvent = {
        type: 'assessment',
        f_o_b: 'b',
        brigade_id: null,
        fitter_id: null,
        order_id: null,
        request_id: null,
        client_id: null,
        address_id: null,
        manager_comment: '',
        start_date: new Date(),
        start_time: null,
        end_date: null,
        end_time: null,
      }
      // CLEAR FILTERED
    },
    getSchedule() {
      if (this.filterSelected) {
        this.$store.dispatch(calendarAction.getFiltered, {
          f_o_b: this.newEvent.f_o_b,
          brigade_id: this.newEvent.brigade_id,
          fitter_id: this.newEvent.fitter_id,
          start_date: this.newEvent.start_date,
        })
      }
    },

    clientSearch(queryString, cb) {
      if (queryString.length > 2) {
        autocomplete.searchClient({query: queryString}).then((result) => {
          if (result.data.data.length) {
            cb(result.data.data);
          } else {
            cb([]);
          }
        });
      } else {
        cb([]);
      }
    },
    handleSelectClient(client) {
      this.newEvent.client = client
      this.newEvent.client_id = client.id
      this.newEvent.client_search = client.name
      this.newEvent.request_id = null
      this.newEvent.order_id = null
    },

    loadData() {
      this.$store.dispatch(calendarAction.getAll)
    },
    calcPosition(e) {
      const ts = this.moment('08:00:00', 'HH:mm:ss')
      const start = this.moment(e.start_time, 'HH:mm:ss')
      const end = this.moment(e.end_time, 'HH:mm:ss')
      const topDiff = start - ts
      const top = topDiff / (1000 * 60 * 60) * 50 + 50
      const heightDiff = end - start
      const height = heightDiff / (1000 * 60 * 60) * 50
      let width = '100%';
      if (e.fitter_id)
        width = '50%';
      return {
        top: top + 'px',
        height: height + 'px',
        width: width
      }
    },
    changeDate() {
      this.$store.dispatch(calendarAction.getAll, {date: this.date})
    }
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  },
  mounted() {
    this.loadData()
  }
}
</script>
